@import '../../../styles/basics';
@value button from '../../01_atoms/Button/index.module.scss';

.return-to-donation-popup {
  position: fixed;
  display: block;
  width: 100%;
  right: 0;
  z-index: 5000;
  border-radius: 4px;
  background-color: $white;
  padding: 12px 12px;
  box-shadow: 0px 4px 10px rgba(33,36,49,0.6);
  text-align: center;
  transition: bottom .3s;
  bottom: 0;


  &__icon {
    display: none;
    width: 26px;
    margin: 0 auto 9px auto;
    path {
      fill: $turquoise-1000;
    }
  }

  &__heart-icon {
    display: none;
    width: 26px;
    margin: -34px auto 0 auto;
    path {
      fill: $turquoise-1000;
      stroke: $turquoise-1000;
      stroke-linecap: round;
      transition: all .5s;
    }
  }

  &__accept {
    display: block;
    padding-top: 15px;
    color: $black;
    font-weight: $font-weight-bold;

    &-text {
      display: block;
      margin-inline: 1rem;
      margin-bottom: 9px;
      margin-top: 9px;
    }

    [data-component^="button-"] {
      width: 80%;
      font-weight: $font-weight-bold;
      font-size: 16px;
      margin-inline: 0;
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover {
      color: $turquoise-1000;
      text-decoration: none;
      .return-to-donation-popup__heart-icon path {
        stroke: $turquoise-1000;
        stroke-width: 2px;
        stroke-linecap: round;
        fill: $turquoise-600;
      }
    }
  }

  &__reject {
    position: absolute;
    background: none;
    color: inherit;
    border: none;
    right: 0;
    top: 0;
    width: 18px;
    height: 18px;
    padding: 20px;

    span {
      display: none;
    }

    &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 19px;
      width: 2px;
      background-color: $black;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  @include media-breakpoint-up(md) {
    width: 300px;
    right: 25px;
    bottom: 30px;

    &__reject {
      position: relative;
      font-size: 16px;
      right: unset;
      top: unset;
      width: 100%;
      padding: 0 0 0 10px;
      margin-bottom: 10px;

      &:before, &:after {
        display: none;
      }

      span {
        display: inline;
      }
    }
  }
  &__icon, &__heart-icon {
    display: block;
  }
}
